import React from "react"

import Layout from '../../layouts/default'

import TextFormated from '../../content-elements/text-formated'

export default () => (
    <Layout title="404" locale="de">
      <TextFormated>
        <p>Seite nicht gefunden</p>
      </TextFormated>
    </Layout>
)
